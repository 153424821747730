<template>
  <work-order-list />
</template>

<script>
import WorkOrderList from "../components/List";

export default {
  name: "WorkOrders",

  components: {
    WorkOrderList
  }
};
</script>
