<template>
  <v-container fluid>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 lg6 xl6>
        <v-overlay :value="true" v-if="loading || searching">
          <v-progress-circular :size="100" color="white" indeterminate
            >Loading</v-progress-circular
          >
        </v-overlay>
        <v-card v-if="error">
          <v-alert elevation="8" type="error">
            {{ error }}
            <div class="pt-2"><strong>If this problem persists, please login again.</strong></div>
          </v-alert>
        </v-card>
        <v-card class="mb-10" elevation="12">
          <v-app-bar flat color="primary">
            <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon>
            <v-toolbar-title class="title white--text pl-0">
              {{ title() }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" icon>
              <v-icon @click="showSearch = !showSearch">mdi-magnify</v-icon>
            </v-btn>
          </v-app-bar>
          <v-card
            flat
            v-if="!searching && workOrders && workOrders.length === 0"
          >
            <v-card-text>
              <v-row>
                <v-col v-if="searchByBuilding">
                  <h3>No work orders found.</h3>
                  Modify the search criteria for more results.
                </v-col>
                <v-col v-if="searchBySWO">
                  <h3>No work order found with SWO <strong>{{search.swo}}</strong></h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-expand-transition>
            <v-card
              flat
              class="mt-2"
              v-if="showSearch || workOrders.length === 0"
            >
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text v-show="searchBySWO">
                  <v-row align="center" no-gutters>
                    <v-col class="pt-2"></v-col>
                    <v-col cols="7" class="pt-2">
                      <v-text-field
                        v-model="search.swo"
                        label="Enter Service Work Order #"
                        :autofocus="true"
                        height="62"
                        style="font-size: 20px;"
                        clearable
                        outlined
                        maxlength="7"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col class="pt-2"></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn
                        color="primary"
                        text
                        outlined
                        v-show="searchBySWO"
                        @click="toggleSearch()"
                      >
                        Show Search Options
                      </v-btn>
                    </v-col>
                  </v-row >
                </v-card-text>
                <v-card-text v-show="searchByBuilding">
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="search.building"
                        :items="buildings"
                        label="Select Building"
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="my-0">
                    <v-col cols="auto">
                      <v-card flat>
                        <v-chip color="teal" text-color="white">
                          <v-avatar>
                            <v-icon>mdi-account</v-icon>
                          </v-avatar>
                          Mine
                        </v-chip>
                      </v-card>
                    </v-col>
                    <v-col class="py-0">
                      <v-card flat>
                        <v-switch
                          inset
                          class="py-0"
                          v-model="search.my"
                          label="Find work orders assigned to me"
                        ></v-switch>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row align="center" class="my-0">
                    <v-col cols="auto">
                      <v-card flat>
                        <v-chip color="orange" text-color="white">
                          <v-avatar>
                            <v-icon>mdi-cached</v-icon>
                          </v-avatar>
                          R & R
                        </v-chip>
                      </v-card>
                    </v-col>
                    <v-col class="py-0">
                      <v-card flat>
                        <v-switch
                          inset
                          v-model="search.rr"
                          label="Rounds & Readings"
                        ></v-switch>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn
                        color="primary"
                        text
                        outlined
                        v-show="searchByBuilding"
                        @click="toggleSearch()"
                      >
                        Search By SWO
                      </v-btn>
                      <v-btn
                        color="secondary"
                        text
                        outlined
                        @click="reset()"
                        class="ml-4"
                      >
                        Reset
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-card>
          </v-expand-transition>
          <v-card-text
            class="grey lighten-2 py-1"
            v-if="!searching && workOrders && workOrders.length > 0"
          >
            <em>{{ results }}</em>
          </v-card-text>
          <v-divider></v-divider>
          <v-card
            v-for="(item, i) in currWorkOrders"
            @click="openForm(item)"
            :key="i"
          >
            <v-card-title>{{ listItemTitle(item) }}</v-card-title>
            <v-card-subtitle class="pb-0" v-if="item.building"
              >{{ item.building.partnerId }}
              {{ item.building.name }}</v-card-subtitle
            >
            <v-card-actions
              v-if="item && item.taskType"
              class="d-flex flex-wrap"
            >
              <v-chip color="yellow" class="ma-2">
                SWO: {{ item.partnerId }}</v-chip
              >
              <v-chip :color="frequencyColor(item)" darker class="ma-2">
                <v-avatar left>
                  <v-icon>mdi-calendar</v-icon>
                </v-avatar>
                {{ item.taskType.frequency }}</v-chip
              >
              <v-chip
                v-if="assignedToMe(item)"
                color="teal"
                text-color="white"
                class="ma-2"
              >
                <v-avatar>
                  <v-icon>mdi-account</v-icon>
                </v-avatar>
                Mine
              </v-chip>
              <v-chip
                color="orange"
                v-if="item.taskType.aggregation === 'multiple'"
                text-color="white"
                class="ma-2"
              >
                <v-avatar>
                  <v-icon>mdi-cached</v-icon>
                </v-avatar>
                R & R
              </v-chip>
            </v-card-actions>
            <v-divider></v-divider>
          </v-card>
          <v-card v-if="workOrders && workOrders.length > perPage">
            <v-card-text class="text-center grey lighten-1">
              <v-pagination
                v-model="page"
                class="px-5"
                :total-visible="7"
                :length="pages"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </v-card-text>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const axios = require("axios");

export default {
  name: "WorkOrder",
  data: () => ({
    page: 1,
    perPage: 5,
    overlay: true,
    buildings: [],
    taskTypes: [],
    showSearch: false,
    swoSearch: "",
    workOrders: [],
    loading: false,
    error: false,
    model: undefined,
    search: {
      building: "",
      date: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      },
      taskType: "",
      status: "open",
      my: true,
      rr: false,
      swo: ""
    },
    valid: true,
    searching: false,
    searchBySWO: false,
    searchByBuilding: false
  }),
  created: function() {
    this.initSearch();
    const promises = [];

    //promises.push(this.submitSearch());
    promises.push(
      axios
        .get("/api/building")
        .then(res => {
          if (
            res.status === 200 &&
            res.headers["content-type"] === "application/json; charset=utf-8"
          ) {
            this.buildings = res.data;
            this.buildings = this.buildings.map(b => {
              return {
                text: `${b.partnerId} ${b.name}`,
                value: b.partnerId
              };
            });
            this.buildings.unshift({
              text: "All Buildings",
              value: ""
            });
          } else {
            this.error =
              "An error was encountered while loading active buildings.";
          }
        })
        .catch(err => {
          this.error =
            `We encountered an error while searching for work orders. (${err})`;
        })
    );

    promises.push(
      axios
        .get("/api/taskType")
        .then(res => {
          if (
            res.status === 200 &&
            res.headers["content-type"] === "application/json; charset=utf-8"
          ) {
            this.taskTypes = res.data;
            this.taskTypes = this.taskTypes.map(t => {
              return `${t.name}`;
            });
          } else {
            this.error =
              "We encountered an error while loading data required to search for open work orders.";
          }
        })
        .catch(err => {
          this.error =
            `We encountered an error while searching for work orders. (${err})`;
          //this.$store.commit("logout");
          //this.$router.push("/");
        })
    );

    Promise.all(promises).then(() => {
      this.loading = false;
      this.searchByBuilding = true;
      this.submitSearch();
    });
  },
  watch: {
    search: {
      deep: true,
      handler() {
        if(this.searchByBuilding) {
          this.search.swo = '';
          this.submitSearch();
        } else {
          if(this.search.swo && this.search.swo.length > 6) {
            this.submitSearch();
          }
        }
      }
    }
  },
  computed: {
    results() {
      let s = `${this.workOrders.length} results`;
      if (this.search.building && this.search.building !== "") {
        s = `${s} for building ${this.search.building}`;
      }
      return s;
    },
    pages() {
      return Math.ceil(this.workOrders.length / this.perPage);
    },
    currWorkOrders() {
      if (!this.workOrders || this.workOrders.length === 0) return null;
      const start = (this.page - 1) * this.perPage;
      const end =
        start + this.perPage > this.workOrders.length
          ? this.workOrders.length
          : start + this.perPage;
      return this.workOrders.slice(start, end);
    }
  },
  methods: {
    toggleSearch: function() {
      this.searchBySWO = ! this.searchBySWO;
      this.searchByBuilding = ! this.searchByBuilding;
    },
    frequencyColor: function(item) {
      if (!item || !item.taskType) return "white";
      const f = item.taskType.frequency;
      if (f === "weekly") return "green";
      else if (f === "bi-weekly") return "light-green";
      else if (f === "monthly") return "dark-green";
      else return "lime";
    },
    initSearch: function() {
      this.search = this.$store.state.search || {
          date: {
            year: (new Date()).getFullYear(),
            month: (new Date()).getMonth() + 1
          },
        };
      this.search = {
        building: this.search.building || "",
        date: {
          year: (new Date()).getFullYear(),
          month: (new Date()).getMonth() + 1

        },
        taskType: this.search.taskType || "",
        status: this.search.status || "open",
        my: this.search.my || true,
        rr: this.search.rr || false
      };
    },
    listItemTitle: function(wo) {
      if (wo && wo.taskType) {
        return `${wo.taskType.description}`;
      } else {
        return "";
      }
    },
    validate: function() {
      this.$refs.form.validate();
      if (this.valid) this.submitSearch();
    },
    reset: function() {
      this.search = {
        building: "",
        taskType: "",
        date: {
          year: new Date().getFullYear(),
          month: new Date().getMonth() + 1
        },
        status: "open",
        my: true
      };
      this.submitSearch();
    },
    submitSearch: function() {
      this.searching = true;
      this.page = 1;
      // Save search
      const p = axios
        .post("/api/search/workOrders", this.search)
        .then(res => {
          if (
            res.status === 200 &&
            res.headers["content-type"] === "application/json; charset=utf-8"
          ) {
            this.error = false;
            this.showSearch = false;
            this.workOrders = res.data;
            this.$store.commit("search", this.search);
          } else {
            this.workOrders = [];
            this.error =
              "We encountered an error while searching for work orders.";
          }
        })
        .catch(err => {
          this.error =
            `We encountered an error while searching for work orders. (${err})`;
        })
        .finally(() => {
          this.searching = false;
        });
      return p;
    },
    openForm: function(wo) {
      this.$router.push({ name: "form", params: { id: wo._id } });
      return true;
    },
    assignedToMe: function(wo) {
      const name = this.$store.state.user ? this.$store.state.user.name : "";
      let found = false;
      if (wo.chief.name === name) return true;
      wo.technicians.forEach(t => {
        if (t.name === name) {
          found = true;
          return;
        }
      });
      return found;
    },
    title: function() {
      if (this.search.my) {
        return `My ${this.search.status.charAt(0).toUpperCase() +
          this.search.status.slice(1)} Work Orders`;
      }

      if (this.search.status) {
        return `${this.search.status.charAt(0).toUpperCase() +
          this.search.status.slice(1)} Work Orders`;
      } else {
        return "";
      }
    }
  } // methods
};
</script>
